
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import { ColumnDefinition, TableDefinition } from '@/types';
import router from '@/router';
import coreStore from '@/store/CoreStore';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Transaction from '@/domain/Transaction';
import PlannedTransaction from '@/domain/PlannedTransaction';
import TransactionService from '@/services/TransactionService';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import Permissions from '@/services/permissions/Permissions';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';

type State = {
    loading: boolean;
    plannedTransactions: Array<PlannedTransaction>;
    saving: boolean;
    selectedItems: PlannedTransaction[];
};

export default defineComponent({
    name: 'master-data-planned-transaction-list',
    components: {
        BDropdown,
        BDropdownItem,
        Screen,
        BButton,
        BAdvancedTable,
        SimpleDatePopover,
    },
    setup() {
        const transactionService = new TransactionService();
        const shipmentService = new SupplierShipmentService();
        const { userLocation } = coreStore.getInstance().profileStore;
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            loading: true,
            plannedTransactions: [],
            saving: false,
            selectedItems: [],
        });

        const columnDefinition: Array<ColumnDefinition<PlannedTransaction>> = [
            {
                key: 'id',
                label: getTitleCaseTranslation('core.domain.transactionNumber'),
                searchable: true,
                width: '120px',
            },
            {
                key: 'fromLocationName',
                label: getTitleCaseTranslation('core.common.fromLoc'),
                searchable: false,
            },
            {
                key: 'toLocationName',
                label: getTitleCaseTranslation('core.common.toLoc'),
                searchable: true,
            },
            {
                key: 'createdOn',
                label: getTitleCaseTranslation('core.domain.placementDate'),
            },
            {
                key: 'shipDate',
                label: getTitleCaseTranslation('core.domain.shipDate'),
                searchable: false,
            },
            {
                key: 'dateRequired',
                label: getTitleCaseTranslation('core.domain.arrivalDate'),
                searchable: true,
            },
            {
                key: 'transactionStatusDescription',
                label: getTitleCaseTranslation('core.domain.status'),
                searchable: false,
            },
        ];

        onBeforeMount(async () => {
            const response = await transactionService.getOutboundPlannedTransactions(userLocation.id);
            if (response.success && response.transactions) {
                state.plannedTransactions = response.transactions;
            }
            state.loading = false;
        });

        const allTransactionsTable = computed(
            (): TableDefinition<PlannedTransaction> => ({
                items: state.plannedTransactions,
                key: 'masterDataPlannedTransactionList',
                name: getTranslation('core.common.plannedTransactions'),
                columnDefinition,
            }),
        );

        function editTransaction(transaction: Transaction) {
            router.push({
                name: MasterDataRouteTypes.TRANSACTION.EDIT,
                params: { transactionId: transaction.id.toString() },
            });
        }

        async function allocateTransaction(transaction: PlannedTransaction) {
            const isConfirmed = await confirm({
                title: getTitleCaseTranslation('core.button.allocateTransaction'),
                message: getTranslation('core.common.areYouSureYouWantToAllocateThisTransaction'),
            });

            if (isConfirmed) {
                state.saving = true;
                let worked = false;
                if (transaction.fromLocationId === undefined) {
                    const response = await shipmentService.createAllocatedShipmentFromPlannedTransactionForLocation(transaction.id, userLocation.id);
                    worked = response && response.success;
                } else {
                    const response = await shipmentService.createAllocatedShipmentFromPlannedTransaction(transaction.id);
                    worked = response && response.success;
                }
                if (worked) {
                    state.plannedTransactions = state.plannedTransactions.filter((t) => t.id !== transaction.id);
                }
                state.saving = false;
            }
        }

        async function deleteTransaction(transaction: PlannedTransaction) {
            if (transaction.fromLocationId === undefined) {
                return;
            }

            const isConfirmed = await confirm({
                title: getTitleCaseTranslation('core.button.deleteTransaction'),
                message: getTranslation('core.common.areYouSureYouWantToDeleteThisTransaction'),
            });

            if (isConfirmed) {
                state.saving = true;
                const response = await transactionService.deleteTransactionById(transaction.id);
                if (response) {
                    state.plannedTransactions = state.plannedTransactions.filter((t) => t.id !== transaction.id);
                }
                state.saving = false;
            }
        }

        async function allocateSelectedTransactions() {
            const isConfirmed = await confirm({
                title: getTitleCaseTranslation('core.button.allocateSelectedTransactions'),
                message: getTranslation('core.common.areYouSureYouWantToAllocateAllSelectedTransactions'),
            });

            if (isConfirmed) {
                state.saving = true;
                const withoutFrom = state.selectedItems.filter((t) => t.fromLocationId === undefined).map((t) => t.id);
                const withFrom = state.selectedItems.filter((t) => t.fromLocationId !== undefined).map((t) => t.id);

                if (withoutFrom.length > 0) {
                    const response = await shipmentService.createAllocatedShipmentsFromPlannedTransactionsForLocation(withoutFrom, userLocation.id);
                    if (response && response.success) {
                        state.plannedTransactions = state.plannedTransactions.filter((t) => withoutFrom.indexOf(t.id) < 0);
                    }
                }

                if (withFrom.length > 0) {
                    const response = await shipmentService.createAllocatedShipmentsFromPlannedTransactions(withFrom);
                    if (response && response.success) {
                        state.plannedTransactions = state.plannedTransactions.filter((t) => withFrom.indexOf(t.id) < 0);
                    }
                }
                state.saving = false;
            }
        }

        function hasFromLocation(transaction: PlannedTransaction) {
            return transaction.fromLocationId !== undefined;
        }

        return {
            allTransactionsTable,
            state,
            Permissions,
            editTransaction,
            allocateTransaction,
            deleteTransaction,
            allocateSelectedTransactions,
            hasFromLocation,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
