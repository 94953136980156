import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_b_advanced_table, {
          id: "planned-transactions-table",
          "selected-items": _ctx.state.selectedItems,
          "table-array": [_ctx.allTransactionsTable],
          "sticky-header": "calc(100vh - 100px)",
          loading: _ctx.state.loading || _ctx.state.saving,
          "enable-multiselect": ""
        }, {
          toprow: _withCtx(() => [
            _createVNode(_component_b_button, {
              size: "sm",
              variant: "tertiary",
              disabled: _ctx.state.selectedItems.length === 0,
              onClick: _ctx.allocateSelectedTransactions
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.allocateSelectedTransactions')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          action: _withCtx((row) => [
            _createVNode(_component_b_dropdown, {
              "no-caret": "",
              "close-on-click": ""
            }, {
              "button-content": _withCtx(() => [
                _createVNode(_component_faicon, { icon: "ellipsis-h" })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_b_dropdown_item, {
                  size: "sm",
                  onClick: () => _ctx.editTransaction(row.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_b_dropdown_item, {
                  size: "sm",
                  onClick: () => _ctx.allocateTransaction(row.item)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.allocateTransaction')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                (_ctx.Permissions.ADMINISTRATION.canDeleteTransaction() && _ctx.hasFromLocation(row.item))
                  ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                      key: 0,
                      onClick: () => _ctx.deleteTransaction(row.item)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.deleteTransaction')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          "cell(shipDate)": _withCtx((row) => [
            _createVNode(_component_simple_date_popover, {
              date: row.item.shipDate
            }, null, 8, ["date"])
          ]),
          "cell(createdOn)": _withCtx((row) => [
            _createVNode(_component_simple_date_popover, {
              date: row.item.createdOn
            }, null, 8, ["date"])
          ]),
          "cell(dateRequired)": _withCtx((row) => [
            _createVNode(_component_simple_date_popover, {
              date: row.item.dateRequired
            }, null, 8, ["date"])
          ]),
          _: 1
        }, 8, ["selected-items", "table-array", "loading"])
      ])
    ]),
    _: 1
  }))
}